<template>
  <div>
    <nuxt-link
      :to="{ name: 'casino-providerId-gameId', params: { providerId: providerId, gameId: game.slug } }">
      <div class="feature-image relative" @mouseover="selectIndex(`${item.id}_${game.id}`);" @mouseout="ItemHover = null;">
        <OverGameImage v-show="ItemHover == `${item.id}_${game.id}`" v-if="mounted" />
        <MipixImage :src="game.image" :alt="game.title" class="w-full rounded-lg" />
      </div>
      <div class="w-full mt-2">
        <h4 class="font-medium text-xs text-start sm:text-start overflow-hidden text-nowrap">{{ game.title }}</h4>
      </div>
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
import type Game from '~/interfaces/game';
import type Lobby from '~/interfaces/lobby';
import type Provider from '~/interfaces/provider';

const props = defineProps<{ lobby?: Lobby, provider?: Provider, game: Game }>()

const mounted = useMounted()
const providerId = computed<string>(() => props.lobby ? props.game.merchant.slug : props.provider?.slug!)
const item = computed<Lobby | Provider>(() => props.lobby! ?? props.provider!)

const ItemHover = ref<string | null>(null)
const selectIndex = (index: string | null) => ItemHover.value = index;
</script>