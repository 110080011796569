<template>
  <div
    class="absolute w-full h-full flex items-center justify-center bg-black/60 rounded-lg z-10"
  >
    <div
      class="before:w-14 before:h-14 before:scale-95 before:hover:scale-110 before:transition-all before:duration-500 flex justify-center items-center w-14 h-14 before:border-2 before:border-white before:rounded-full before:content-[''] before:absolute opacity-90 before:bg-[#ffffff1b] before:z-[-1] hover:text-white z-20"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill="currentColor"
          d="M21.5801 9.42917L8.23933 1.39939C7.80365 1.13377 7.3098 0.995998 6.80801 1.00009C6.30623 1.00418 5.81441 1.14999 5.38259 1.42268C4.96189 1.67938 4.61237 2.0503 4.36954 2.49776C4.1267 2.94522 3.99921 3.45328 4 3.97032V20.0299C3.99921 20.5469 4.1267 21.055 4.36954 21.5024C4.61237 21.9499 4.96189 22.3208 5.38259 22.5775C5.81445 22.8501 6.30625 22.9958 6.80802 22.9999C7.30978 23.004 7.80361 22.8663 8.23933 22.6008L21.5801 14.571C22.0103 14.3168 22.3689 13.9442 22.6186 13.4921C22.8683 13.04 23 12.5248 23 12.0001C23 11.4754 22.8683 10.9602 22.6186 10.5081C22.3689 10.056 22.0103 9.68345 21.5801 9.42917Z"
        ></path>
      </svg>
    </div>
  </div>
</template>
